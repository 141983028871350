import axios from "axios";
import { accountList } from "plugins/urls";
import { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddBank } from "./AddBank";
import "./SettlementPreferenceStyles.scss";

export const SettlementPrefenceTable = () => {
  const [open, setOpen] = useState(false);
  const [accountListDt, setAccountListDt] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getAccountList = () => {
    axios.get(accountList).then((res) => {
      if (res.status === 200) {
        setAccountListDt(res.data.respBody);
      }
    });
  };

  useEffect(() => {
    getAccountList();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="container header">
        <button className="button" onClick={handleClickOpen}>
          {"Add New Bank"}
        </button>
      </div>
      <Table>
        <thead>
          <tr className="t-head-colored tr">
            <th>Bank Name</th>
            <th>Account Name</th>
            <th>Account Number</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {accountListDt?.map((dt) => {
            const { bankName, accountNumber, accountName, makePrimary } = dt;

            return (
              <tr>
                <td>{bankName}</td>
                <td>
                  {accountName}
                  {makePrimary && (
                    <Badge style={{ marginLeft: "4px" }} pill bg="primary">
                      {"Default"}
                    </Badge>
                  )}
                </td>
                <td>{accountNumber}</td>
                <td>
                  <div>
                    <button className="button btn-sm d-flex align-items-center">
                      <RiDeleteBin6Line className="white" />
                      {"Delete"}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AddBank open={open} onClose={handleClose} setOpen={setOpen} />
    </div>
  );
};
